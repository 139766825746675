<template>
  <div>
    <el-table
      class="desktop--table org--table training--table"
      :data="modules"
      style="width: 100%"
      row-key="id"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="name" label="Name" sortable width="400" />
      <el-table-column prop="description" label="Description" sortable>
        <template #default="scope">
          <el-tooltip
            class="box-item"
            effect="light"
            :content="scope.row.description"
            placement="top"
          >
            <i class="fa fa-eye blue"></i>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="points" label="Points" sortable />
      <el-table-column prop="duration" label="Duration" sortable>
        <template #default="scope">
          <div class="d-flex">{{ scope.row.duration }}h</div>
        </template>
      </el-table-column>

      <el-table-column label="">
        <template #header>
          <el-popconfirm
            :title="$t('Are you sure to delete all modules ?')"
            :style="
              paginatedItems.length > 0 &&
              multipleSelection.length != 0 &&
              multipleSelection.length === paginatedItems.length
                ? 'display:unset'
                : 'display:none'
            "
            @confirm="deleteSelectedModule"
          >
            <template #reference>
              <el-button
                link
                size="small"
                type="danger"
                :style="
                  multipleSelection.length != 0 &&
                  multipleSelection.length === paginatedItems.length
                    ? 'display:unset'
                    : 'display:none'
                "
              >
                {{ $t("Delete all") }}</el-button
              >
            </template>
          </el-popconfirm>
          <el-popconfirm
            :title="$t('Are you sure to delete selected modules ?')"
            :style="
              multipleSelection.length &&
              multipleSelection.length != paginatedItems.length
                ? 'display:unset'
                : 'display:none'
            "
            @confirm="deleteSelectedModule"
          >
            <template #reference>
              <el-button
                link
                size="small"
                type="danger"
                :style="
                  multipleSelection.length &&
                  multipleSelection.length != paginatedItems.length
                    ? 'display:unset'
                    : 'display:none'
                "
                >{{ $t("Delete selected") }}</el-button
              >
            </template>
          </el-popconfirm>
        </template>
        <template #default="scope">
          <div class="d-flex">
            <el-button
              link
              type="primary"
              class="duplicate--btn"
              size="small"
              @click="$emit('openAddModuleModal', true, scope.row)"
              v-if="canEdit"
              >Edit</el-button
            >
            <el-button
              link
              type="primary"
              class="duplicate--btn"
              size="small"
              @click="deleteModule(scope.row.id)"
              v-if="canDelete"
              >Delete</el-button
            >
          </div>
          <!-- @click="handleClick" -->
        </template>
      </el-table-column>
    </el-table>
    <div style="min-height: 100px" class="small--screen--table">
      <div
        v-for="(item, index) in modules"
        :key="index"
        :name="index"
        class="bg-white text-dark d-flex p-3 mobile---card"
      >
        <div
          class="d-flex align-items-center justify-content-between flex-wrap w-90 px-5 align-self-end"
          style="gap: 10px 32px"
        >
          <div class="w-100 d-flex gap-5">
            <div class="d-flex flex-column w-50 align-items-start w-50 gap-1">
              <span for="" class="info--keys">{{ $t("Title") }}</span>
              <span for="" class="info--values">{{ item.name }}</span>
            </div>
            <div class="d-flex flex-column w-50 align-items-start gap-1">
              <span for="" class="info--keys">{{ $t("Description") }}</span>
              <i
                class="fa fa-eye blue"
                v-c-tooltip="{
                  content: item.description,
                  placement: 'top',
                }"
              ></i>
            </div>
          </div>

          <div class="w-100 d-flex gap-5">
            <div class="d-flex flex-column w-50 align-items-start w-50 gap-1">
              <span for="" class="info--keys">{{ $t("Points") }}</span>
              <span for="" class="info--values">{{ item.points }}</span>
            </div>
            <div
              class="d-flex align-items-start justify-content-start flex-column gap-1"
              style="width: 33.33%"
            >
              <span for="" class="info--keys">{{ $t("Actions") }}</span>
              <span for=""
                ><el-button
                  size="small"
                  link
                  type="primary"
                  class="action_btn"
                  @click="$emit('openAddTrainingModal', item, true)"
                  v-if="canEdit"
                  ><i class="fa fa-pen"></i
                ></el-button>
                <el-button
                  link
                  size="small"
                  type="danger"
                  class="action_btn"
                  @click="deleteTraining(item.id)"
                  v-if="canDelete"
                  ><i class="fa fa-trash"></i></el-button
              ></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pagination-org">
      <div>
        <p style="font-size: 13px">
          {{ `${endIndex} / ${totalItems} ${$t("lines")}` }}
        </p>
      </div>
      <div>
        <el-pagination
          v-model:current-page="currentPage"
          v-model:page-size="perPage"
          :small="small"
          :disabled="disabled"
          :background="background"
          layout="prev, pager, next"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
      <div class="d-flex">
        <span class="mt-2 mr-2" style="font-size: 13px"
          >{{ $t("Per page") }}:</span
        >

        <CSelect
          style="border: none"
          :value="perPage === modules.length ? 'All' : perPage"
          :options="perOptions"
          @input="changePerPage($event.target.value)"
        >
        </CSelect>
      </div>
    </div>
  </div>
</template>
<script>
import { ModuleService } from "@/services/module";
import { ElLoading } from "element-plus";
import { showMessage } from "../../../utils/helpers";

export default {
  name: "ModuleTable",
  emits: ["openAddModuleModal", "toggleRefresh"],
  props: ["addModuleM", "refresh", "canEdit", "canDelete"],
  data() {
    return {
      currentPage: 1,
      perPage: 25,
      perOptions: [25, 50, 100, 200, "All"],
      total: 0,
      multipleSelection: [],
      modules: [],
    };
  },
  computed: {
    endIndex() {
      let end = this.currentPage * this.perPage;
      return end > this.totalItems ? this.totalItems : end;
    },
    totalItems() {
      return this.modules.length > 0 ? this.modules.length : 0;
    },
    paginatedItems() {
      if (this.modules) {
        const start = (this.currentPage - 1) * this.perPage;
        const end = this.currentPage * this.perPage;

        const slicedArray = this.modules.slice(start, end);

        return slicedArray;
      }
    },
  },
  mounted() {
    this.getModules();
  },
  methods: {
    async getModules() {
      const loading = ElLoading.service({
        fullscreen: true,
        background: "#0a1f8f45",
      });
      this.modules = [];
      const { res, data } = await ModuleService.getModules(
        this.currentPage,
        this.perPage
      );
      if (res.status == 200) {
        this.modules = data.modules;
        this.total = data.total;
      } else {
      }
      loading.close();
    },
    async deleteModule(id) {
      const loading = ElLoading.service({
        fullscreen: true,
        background: "#0a1f8f45",
      });
      const { res, data } = await ModuleService.deleteModule(id);
      if (res.status == 200) {
        showMessage("success", data);
        this.getModules();
      } else {
        showMessage("error", data);
      }
      loading.close();
    },
    async deleteSelectedModule() {
      const loading = ElLoading.service({
        fullscreen: true,
        background: "#0a1f8f45",
      });
      const { res, data } = await ModuleService.deleteModule(
        this.multipleSelection
      );
      if (res.status == 200) {
        showMessage("success", data);
        this.getModules();
      } else {
        showMessage("error", data);
      }
      loading.close();
    },
    changePerPage(value) {
      if (value === "All") {
        this.perPage = this.total;
        this.currentPage = 1;
      } else {
        this.perPage = value;
        this.currentPage = 1;
      }
      this.getModules();
    },

    handleSelectionChange(val) {
      this.multipleSelection = [];
      val.map((el) => this.multipleSelection.push(el.id));
    },
    handlePageChange(newPage) {
      this.currentPage = newPage;
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage;
      }
    },
    nextPage() {
      if (this.currentPage < this.total) {
        this.currentPage;
      }
    },
    updateDisplayedItems() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = Math.min(start + this.perPage, this.modules.length);
      this.displayedItems = end - start;
    },
    currentPage() {
      this.updateDisplayedItems();
    },
    perPage() {
      this.getModules();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getModules();
    },
    handleSizeChange(val) {
      console.log(`${val} items per page`);
      this.perPage = val;
      this.getModules();
    },
  },
  watch: {
    addModuleM(val, old_val) {
      if (!val && old_val && this.refresh) {
        this.getModules();
        this.$emit("toggleRefresh");
      }
    },
  },
};
</script>
<style scoped>
.mobile---card {
  border: 1px solid #c3c3c3;
  border-bottom: none;
}

.small--screen--table {
  display: none;
}

@media (max-width: 970px) {
  .desktop--table {
    display: none;
  }

  .small--screen--table {
    display: block;
  }

  .org--table .el-table__body-wrapper {
    max-height: calc(100dvh - 380px);
  }
}

@media (max-width: 770px) {
  .desktop--table {
    display: none;
  }

  .small--screen--table {
    display: block;
  }

  .org--table .el-table__body-wrapper {
    max-height: calc(100dvh - 380px);
  }
}
</style>
<style>
.el-table__header {
  background: white;
  border-bottom: 1px solid;
}

.el-table__header thead {
  color: #434343;
}
.borderBottomSelect .el-select__wrapper {
  box-shadow: none;
  border-bottom: 1px solid #c3c3c3;
  border-radius: 0;
}
@media screen and (max-width: 1000px) {
  .el-table .el-table__cell {
    padding: 8px 8px;
  }

  .el-table .el-table__cell div {
    padding: 0;
  }

  .action_btn {
    display: unset;
  }

  .el-table {
    flex-direction: row;
    display: flex;
  }
}

.small--screen--table {
  display: none;
}
</style>
