<template>
  <div class="training_container">
    <div class="filter--container">
      <div class="filter-input-container">
        <label>{{ $t("Title") }}</label>
        <el-input
          v-model="title"
          disabled
          class="filter-input"
          placeholder="Please input"
        />
      </div>
      <div class="filter-input-container">
        <label>{{ $t("Description") }}</label>
        <el-input
          v-model="description"
          disabled
          class="filter-input"
          placeholder="Please input"
        />
      </div>
      <div class="filter-input-container">
        <label>{{ $t("Group") }}</label>
        <el-select
          class="filterItem"
          v-model="group"
          multiple
          collapse-tags
          collapse-tags-tooltip
          :max-collapse-tags="1"
          placeholder="Select"
          @change="filter()"
          filterable
          allow-create
        >
          <el-option
            v-for="(item, index) in groups"
            :key="index"
            :label="item"
            :value="item"
          />
        </el-select>
      </div>
      <div class="filter-input-container">
        <label>{{ $t("Status") }}</label>
        <el-select
          v-model="status"
          multiple
          class="filterItem"
          collapse-tags
          collapse-tags-tooltip
          :max-collapse-tags="1"
          @change="filter()"
          placeholder="Select"
          filterable
          allow-create
        >
          <el-option
            v-for="(item, index) in statusOptions"
            :key="index"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
      <div class="filter-input-container">
        <label>{{ $t("Module") }}</label>
        <el-input
          v-model="module"
          disabled
          class="filter-input"
          placeholder="Please input"
        />
      </div>
    </div>
    <el-table
      class="desktop--table org--table training--table"
      :data="trainings"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column type="expand">
        <template #default="props">
          <div class="module--cards--container">
            <ModuleCard
              :module="item"
              v-for="(item, index) in props.row.modules_data"
              :key="index"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="name" :label="$t('Title')" sortable />
      <el-table-column prop="description" :label="$t('Description')" sortable>
        <template #default="scope">
          <el-tooltip
            class="box-item"
            effect="light"
            :content="scope.row.description"
            placement="top"
          >
            <i class="fa fa-eye blue"></i>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="group" :label="$t('Group')" sortable />
      <el-table-column label="Status" sortable>
        <template #default="scope">
          <el-select
            v-model="scope.row.status"
            placeholder="Select"
            @change="() => changeStatus(scope.row.id)"
            style="width: 100%"
          >
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </template>
      </el-table-column>
      <el-table-column :label="$t('Sessions')" sortable>
        <template #default="scope">
          <div>
            <div v-for="(item, index) in scope.row.sessions" :key="index">
              {{ generateTrainingAttendence(scope.row, item.id) }}/
              {{ scope.row.max_attendance }}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('Module')" sortable>
        <template #default="scope">
          <el-tooltip
            class="box-item"
            effect="light"
            :content="
              scope.row.modules_data.map((item) => item.name).join(', ')
            "
            placement="top"
          >
            <i class="fa fa-eye blue"></i>
          </el-tooltip>
        </template>
      </el-table-column>

      <el-table-column label="">
        <template #header>
          <el-popconfirm
            :title="$t('Are you sure to delete all modules ?')"
            :style="
              paginatedItems.length > 0 &&
              multipleSelection.length != 0 &&
              multipleSelection.length === paginatedItems.length
                ? 'display:unset'
                : 'display:none'
            "
            @confirm="deleteTrainings"
          >
            <template #reference>
              <el-button
                link
                size="small"
                type="danger"
                :style="
                  multipleSelection.length != 0 &&
                  multipleSelection.length === paginatedItems.length
                    ? 'display:unset'
                    : 'display:none'
                "
              >
                {{ $t("Delete all") }}</el-button
              >
            </template>
          </el-popconfirm>
          <el-popconfirm
            :title="$t('Are you sure to delete selected trainings ?')"
            :style="
              multipleSelection.length &&
              multipleSelection.length != paginatedItems.length
                ? 'display:unset'
                : 'display:none'
            "
            @confirm="deleteTrainings"
          >
            <template #reference>
              <el-button
                link
                size="small"
                type="danger"
                :style="
                  multipleSelection.length &&
                  multipleSelection.length != paginatedItems.length
                    ? 'display:unset'
                    : 'display:none'
                "
                >{{ $t("Delete selected") }}</el-button
              >
            </template>
          </el-popconfirm>
        </template>
        <template #default="scope">
          <div class="d-flex">
            <el-button
              link
              type="primary"
              class="duplicate--btn"
              size="small"
              @click="$emit('openAddTrainingModal', scope.row, true)"
              v-if="canManageTrainings"
              >Edit</el-button
            >
            <el-popconfirm
              title="Are you sure to delete this training?"
              @confirm="deleteTraining(scope.row.id)"
              v-if="canManageTrainings"
            >
              <template #reference>
                <el-button link class="duplicate--btn" size="small"
                  >Delete</el-button
                >
              </template>
            </el-popconfirm>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-collapse style="min-height: 100px" class="small--screen--table">
      <el-collapse-item
        v-for="(item, index) in trainings"
        :key="index"
        :name="index"
      >
        <template #title>
          <div
            class="d-flex align-items-center justify-content-between flex-wrap w-90 px-5 align-self-end"
            style="gap: 10px 32px"
          >
            <div class="w-100 d-flex gap-5">
              <div class="d-flex flex-column w-50 align-items-start w-50">
                <span for="" class="info--keys">{{ $t("Title") }}</span>
                <span for="" class="info--values">{{ item.name }}</span>
              </div>
              <div class="d-flex flex-column w-50 align-items-start w-50">
                <span for="" class="info--keys">{{ $t("Group") }}</span>
                <span for="" class="info--values">{{ item.group }}</span>
              </div>
            </div>

            <div class="d-flex flex-column w-100 align-items-start">
              <span for="" class="info--keys">{{ $t("Description") }}</span>
              <i
                class="fa fa-eye blue"
                v-c-tooltip="{
                  content: item.description,
                  placement: 'top',
                }"
              ></i>
            </div>
            <div class="w-100 d-flex gap-5">
              <div
                class="d-flex align-items-start justify-content-start flex-column"
                style="width: 33.33%"
              >
                <span for="" class="info--keys">{{ $t("Status") }}</span>
                <el-select
                  v-model="item.status"
                  placeholder="Select"
                  @change="() => changeStatus(item.id)"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in statusOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </div>
              <div
                class="d-flex align-items-start justify-content-start flex-column"
                style="width: 33.33%"
              >
                <span for="" class="info--keys">{{ $t("Sessions") }}</span>
                <div v-for="(session, index) in item.sessions" :key="index">
                  {{ item.min_attendance }}/ {{ item.max_attendance }}
                </div>
              </div>
              <div
                class="d-flex align-items-start justify-content-start flex-column"
                style="width: 33.33%"
              >
                <span for="" class="info--keys">{{ $t("Modules") }}</span>
                <div>
                  <span
                    v-for="(item, index) in item.modules_data"
                    :key="index"
                    >{{ item.name }}</span
                  >
                </div>
              </div>

              <div
                class="d-flex align-items-start justify-content-start flex-column"
                style="width: 33.33%"
              >
                <span for="" class="info--keys">{{ $t("Actions") }}</span>
                <span for=""
                  ><el-button
                    size="small"
                    link
                    type="primary"
                    v-if="canManageTrainings"
                    class="action_btn"
                    @click="$emit('openAddTrainingModal', item, true)"
                    ><i class="fa fa-pen"></i
                  ></el-button>
                  <el-button
                    link
                    size="small"
                    type="danger"
                    class="action_btn"
                    v-if="canManageTrainings"
                    @click="deleteTraining(item.id)"
                    ><i class="fa fa-trash"></i></el-button
                ></span>
              </div>
            </div>
          </div>
        </template>
        <div
          class="px-4"
          style="border: 1px solid #c3c3c3 !important; margin: 5px 5px"
        >
          <ModuleCard
            :module="item"
            v-for="(item, index) in item.modules_data"
            :key="index"
          />
        </div>
      </el-collapse-item>
    </el-collapse>
    <div class="pagination-org">
      <div>
        <p style="font-size: 13px">
          {{ `${endIndex} / ${totalItems} ${$t("lines")}` }}
        </p>
      </div>

      <el-pagination
        v-model:current-page="currentPage"
        v-model:page-size="perPage"
        :small="small"
        :disabled="disabled"
        :background="background"
        layout="prev, pager, next"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
      <div class="d-flex">
        <span class="mt-2 mr-2" style="font-size: 13px"
          >{{ $t("Per page") }}:</span
        >
        <CSelect
          class="perPageOptions"
          style="border: none"
          :options="perOptions"
          @input="changePerPage($event.target.value)"
        >
        </CSelect>
      </div>
    </div>
  </div>
</template>
<script>
import { ElLoading } from "element-plus";
import { TrainingService } from "../../../services/training";
import ModuleCard from "./ModuleCard.vue";
import { showMessage } from "../../../utils/helpers";

export default {
  name: "TrainingTable",
  components: { ModuleCard },
  emits: ["openAddTrainingModal"],
  props: ["refresh", "addTrainingModal", "canManageTrainings"],
  computed: {
    endIndex() {
      let end = this.currentPage * this.perPage;
      return end > this.totalItems ? this.totalItems : end;
    },
    totalItems() {
      return this.trainings ? this.trainings.length : 0;
    },
    paginatedItems() {
      if (this.trainings) {
        const start = (this.currentPage - 1) * this.perPage;
        const end = this.currentPage * this.perPage;

        const slicedArray = this.trainings.slice(start, end);

        return slicedArray || 0;
      }
    },
  },
  data() {
    return {
      perOptions: [25, 50, 100, 200, "All"],
      currentPage: 1,
      perPage: 25,
      multipleSelection: [],
      statusOptions: [
        { label: "Draft", value: "draft" },
        { label: "Active", value: "active" },
      ],
      trainings: [],
      groups: [],
      group: [],
      status: [],
      module: "",
      description: "",
      title: "",
      total: 0,
    };
  },
  mounted() {
    this.getTrainings();
    this.getGroups();
  },
  methods: {
    async getGroups() {
      const { data } = await TrainingService.get_groups();

      this.groups = data;
    },
    generateTrainingAttendence(training, session_id) {
      return Object.keys(training).includes("users")
        ? training.users.filter((el) => el.sessions_id == session_id).length
        : 0;
    },
    async getTrainings() {
      let payload = {
        groups: this.group,
        title: this.title,
        description: this.description,
        statuses: this.status,
        module: this.module,
        page: this.currentPage,
        count: this.perPage,
      };

      const loading = ElLoading.service({
        fullscreen: true,
        background: "#0a1f8f45",
      });
      this.trainings = [];
      const { res, data } = await TrainingService.get_trainings(payload);
      if (res.status == 200) {
        this.trainings = data.trainings;
        this.total = data.total;
      } else {
        showMessage("error", data);
      }
      loading.close();
    },
    async changeStatus(id) {
      const { res, data } = await TrainingService.training_status(id);
      if (res.status == 200) {
        showMessage("success", data.message);
        this.getTrainings();
      } else {
        showMessage("error", data.message);
      }
    },
    async filter() {
      let payload = {
        groups: this.group,
        title: this.title,
        description: this.description,
        statuses: this.status,
        module: this.module,
        page: 1,
        count: 100,
      };
      const loading = ElLoading.service({
        fullscreen: true,
        background: "#0a1f8f45",
      });
      this.trainings = [];
      const { res, data } = await TrainingService.get_trainings(payload);
      if (res.status == 200) {
        this.trainings = data.trainings;
        this.total = data.total;
      } else {
        showMessage("error", data);
      }
      loading.close();
    },
    changePerPage(value) {
      if (value === "All") {
        this.perPage = this.total;
        this.currentPage = 1;
      } else {
        this.perPage = value;
        this.currentPage = 1;
      }
      this.getTrainings();
      // this.displayedItems = Math.min(this.trainings.length, this.perPage);
      // this.totalPages = Math.ceil(this.trainings.length / this.perPage);
    },
    handleSelectionChange(val) {
      //  = val.id;
      this.multipleSelection = [];
      val.map((el) => this.multipleSelection.push(el.id));
    },
    handlePageChange(newPage) {
      this.currentPage = newPage;
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage;
      }
    },
    //     nextPage() {
    // this.page=this.currentPage+1;
    // },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTrainings();
    },
    handleSizeChange(val) {
      this.perPage = val;
    },
    updateDisplayedItems() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = Math.min(start + this.perPage, this.trainings.length);
      this.displayedItems = end - start;
    },
    currentPage() {
      this.updateDisplayedItems();
    },
    perPage() {
      this.updateDisplayedItems();
    },
    async deleteTrainings() {
      const { res, data } = await TrainingService.delete_training(
        this.multipleSelection
      );
      if (res.status == 200) {
        showMessage("success", data);
        this.getTrainings();
      } else {
        showMessage("error", data);
      }
    },
    async deleteTraining(id) {
      const { res, data } = await TrainingService.delete_training([id]);
      if (res.status == 200) {
        showMessage("success", data);
        this.getTrainings();
      } else {
        showMessage("error", data);
      }
    },
  },
  watch: {
    addTrainingModal(val, old_val) {
      if (!val && old_val && this.refresh) {
        this.getTrainings();
        this.$emit("toggleRefresh");
      }
    },
  },
};
</script>
<style scoped>
.training_container {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.filter--container {
  display: flex;
  gap: 5px;
  margin-bottom: 5px;
  /* height: 60px; */
  flex-wrap: wrap;
}

.filter-input-container {
  flex: 0 1 calc(100% / 5 - 4px);
  /* height: 40px; */
  border: none;
  box-shadow: none;
  outline: none;
  /* border-bottom: 1px solid #c3c3c3; */
}
</style>
<style>
.el-table__header {
  background: white;
  border-bottom: 1px solid;
}

.el-table__header thead {
  color: #434343;
}
/* .borderBottomSelect .el-select__wrapper {
    box-shadow: none;
    border-bottom: 1px solid #c3c3c3;
    border-radius: 0;
} */

.info--keys {
  font-weight: 500;
  color: gray;
}
.info--values {
  font-size: 17px;
  font-weight: 500;
}
.filter-input .el-input__wrapper,
.filter-input .el-select__wrapper {
  box-shadow: none !important;
  outline: none !important;
  border: 1px solid #c3c3c3;
  border-radius: 0 !important;
}

.filter-input .el-input__wrapper:focus {
  border-bottom: 1px solid #3399ff;
  box-shadow: none !important;
  display: none;
}

.pagination-org {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  background-color: #fff;
  height: 58px;
  width: 100%;
  padding: 40px 20px 25px 20px;
  border-right: 1px solid rgb(195, 195, 195);
  border-bottom: 1px solid rgb(195, 195, 195);
  border-left: 1px solid rgb(195, 195, 195);
  border-top: 1px solid rgb(195, 195, 195);
}
.el-select__selection {
  gap: 2px;
}
.training--table {
  width: 100vw;
  max-height: 60vh;
}

.training--table .el-table__body-wrapper {
  max-height: calc(100dvh - 450px) !important;
  overflow-y: auto;
}

.training--table tr {
  width: 100vw;
  overflow-x: hidden;
}

.training--table .el-table__cell.el-table__expanded-cell {
  overflow: auto;
}

.module--cards--container {
  width: fit-content;
  overflow: auto;
  padding: 0px 8px;
  gap: 10px;
  display: flex;
}

.duplicate--btn {
  display: none;
}

.el-table__row:hover .duplicate--btn {
  display: block;
  font-weight: 900;
  color: #0071ce;
}
button.el-collapse-item__header {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.training_container .el-collapse-item {
  border: 1px solid #c3c3c3;
  border-bottom: none;
}
.el-collapse-item__arrow {
  margin: 0;
  width: 10%;
  transition: transform var(--el-transition-duration);
  font-weight: 300;
}
.small--screen--table label {
  padding: 0;
  margin: 0;
  height: 15px;
}
.small--screen--table span {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 30px;
}
@media screen and (max-width: 1280px) {
  .org--table .el-table__body-wrapper {
    max-height: calc(100dvh - 380px);
  }
}

@media screen and (max-width: 1000px) {
  .el-table .el-table__cell {
    padding: 8px 8px;
  }
  .el-table .el-table__cell div {
    padding: 0;
  }
  .action_btn {
    display: unset;
  }
  .el-table {
    flex-direction: row;
    display: flex;
  }
}
.small--screen--table {
  display: none !important;
}
@media (max-width: 970px) {
  .desktop--table {
    display: none !important;
  }
  .small--screen--table {
    display: block !important;
  }
  .filter-input-container[data-v-6aac994b] {
    flex: 0 1 calc(100% / 2 - 5px);
  }
  .org--table .el-table__body-wrapper {
    max-height: calc(100dvh - 380px);
  }
  .head--panel {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    gap: 15px;
  }
}
@media (max-width: 770px) {
  .desktop--table {
    display: none;
  }
  .small--screen--table {
    display: block;
  }
  .org--table .el-table__body-wrapper {
    max-height: calc(100dvh - 380px);
  }
}
@media (min-width: 480px) {
  .el-table th,
  .el-table td {
    padding: 1em !important;
  }
}
@media (min-width: 480px) {
  .el-table th,
  .el-table td {
    display: table-cell;
    padding: 0.25em 0.5em;
  }
  .el-table th:first-child,
  .el-table td:first-child {
    padding-left: 0;
  }
  .el-table th:last-child,
  .el-table td:last-child {
    padding-right: 0;
  }
}
.filterItem .el-select__wrapper {
  box-shadow: none !important;
  border: 1px solid #c3c3c3;
  border-radius: 0 !important;
}
.perPageOptions {
  border: none !important;
  cursor: pointer;
}
</style>
