<template>
  <div class="Module--card">
    <div class="header">
      <div class="d-flex w-100 align-items-between justify-content-between">
      <h4 class="header--title">{{ this.module.name }}</h4><el-tag :type="primary" effect="plain" round>
        {{ this.module.points }}
      </el-tag>
    </div>
      <div v-if="$route.name=='Training'">
        <!-- <button class="action--btn">
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.6634 0.165248C13.7157 0.112867 13.7778 0.071308 13.8461 0.042952C13.9144 0.014596 13.9877 0 14.0617 0C14.1357 0 14.2089 0.014596 14.2772 0.042952C14.3456 0.071308 14.4076 0.112867 14.4599 0.165248L17.8348 3.5401C17.8871 3.59235 17.9287 3.65442 17.957 3.72275C17.9854 3.79109 18 3.86435 18 3.93833C18 4.01232 17.9854 4.08558 17.957 4.15391C17.9287 4.22225 17.8871 4.28432 17.8348 4.33657L6.58524 15.5861C6.53126 15.6397 6.46697 15.6818 6.39625 15.7098L0.771498 17.9597C0.669281 18.0006 0.557303 18.0107 0.449447 17.9885C0.34159 17.9664 0.242599 17.9131 0.164745 17.8353C0.0868915 17.7574 0.0335987 17.6584 0.0114738 17.5506C-0.0106511 17.4427 -0.00063514 17.3307 0.0402801 17.2285L2.29018 11.6037C2.31822 11.533 2.36032 11.4687 2.41393 11.4148L13.6634 0.165248ZM12.6071 2.81338L15.1866 5.39289L16.6412 3.93833L14.0617 1.35882L12.6071 2.81338ZM14.3913 6.18823L11.8118 3.60872L4.49959 10.9209V11.2505H5.06206C5.21124 11.2505 5.35431 11.3098 5.45979 11.4153C5.56527 11.5207 5.62454 11.6638 5.62454 11.813V12.3755H6.18701C6.33619 12.3755 6.47926 12.4347 6.58474 12.5402C6.69023 12.6457 6.74949 12.7888 6.74949 12.9379V13.5004H7.0791L14.3913 6.18823ZM3.41063 12.0099L3.29139 12.1291L1.57471 16.4253L5.87315 14.7064L5.99239 14.5871C5.88503 14.5475 5.7923 14.4761 5.72657 14.3825C5.66085 14.2888 5.62525 14.1773 5.62454 14.0629V13.5004H5.06206C4.91288 13.5004 4.76981 13.4412 4.66433 13.3357C4.55885 13.2302 4.49959 13.0871 4.49959 12.9379V12.3755H3.93711C3.82257 12.3754 3.71078 12.3403 3.6167 12.275C3.52262 12.2097 3.45072 12.1172 3.41063 12.0099Z"
              fill="#7B7B7B"
            />
          </svg>
        </button>

        <button class="action--btn">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M19.8079 4.19586C19.87 4.25778 19.9192 4.33135 19.9528 4.41234C19.9864 4.49333 20.0037 4.58016 20.0037 4.66785C20.0037 4.75554 19.9864 4.84236 19.9528 4.92335C19.9192 5.00435 19.87 5.07791 19.8079 5.13984L5.14144 19.8063C5.01626 19.9314 4.84648 20.0018 4.66945 20.0018C4.49242 20.0018 4.32264 19.9314 4.19746 19.8063C4.07228 19.6811 4.00195 19.5113 4.00195 19.3343C4.00195 19.1572 4.07228 18.9875 4.19746 18.8623L18.8639 4.19586C18.9258 4.13377 18.9994 4.08452 19.0804 4.05091C19.1614 4.0173 19.2482 4 19.3359 4C19.4236 4 19.5104 4.0173 19.5914 4.05091C19.6724 4.08452 19.7459 4.13377 19.8079 4.19586Z"
              fill="#7B7B7B"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.19781 4.19586C4.13572 4.25778 4.08647 4.33135 4.05286 4.41234C4.01925 4.49333 4.00195 4.58016 4.00195 4.66785C4.00195 4.75554 4.01925 4.84236 4.05286 4.92335C4.08647 5.00435 4.13572 5.07791 4.19781 5.13984L18.8642 19.8063C18.9894 19.9314 19.1592 20.0018 19.3362 20.0018C19.5133 20.0018 19.683 19.9314 19.8082 19.8063C19.9334 19.6811 20.0037 19.5113 20.0037 19.3343C20.0037 19.1572 19.9334 18.9875 19.8082 18.8623L5.14179 4.19586C5.07987 4.13377 5.0063 4.08452 4.92531 4.05091C4.84432 4.0173 4.75749 4 4.6698 4C4.58211 4 4.49529 4.0173 4.41429 4.05091C4.3333 4.08452 4.25973 4.13377 4.19781 4.19586Z"
              fill="#7B7B7B"
            />
          </svg>
        </button> -->
      </div>
    </div>
    <p class="description">
      {{ this.module.description }}
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi dolorem consectetur nesciunt iure totam impedit aspernatur eligendi. Necessitatibus, deleniti. Reiciendis at ad officia enim adipisci excepturi cupiditate rerum? Velit, praesentium.
    </p>
    <!-- <div class="points--container">
      <span class="points--title">Points</span>
      <el-divider border-style="dashed" />
      <el-tag :type="primary" effect="plain" round>
        {{ this.module.points }}
      </el-tag>
    </div> -->
    <!-- <div class="duration--container">
      <span class="Duration--title">Duration</span>
      <el-divider border-style="dashed" />
      <el-tag :type="primary" effect="plain" round>
        {{ this.module.duration }}h
      </el-tag>
    </div> -->
  </div>
</template>
<script>
export default {
  name: "ModuleCard",
  props: ["module"],
  data() {
    return {};
  },
};
</script>
<style scoped>
.Module--card {
  width: 375px;
  height: 130px;
  position: relative;
  padding: 15px;
  padding-left: 17px;
  border: 1px solid #c3c3c3;

  gap: 10px;
}
.Module--card::before {
  content: "";
  background-color: #3399ff;
  position: absolute;
  left: 0;
  top: 0;
  width: 4px;
  height: 100%;
}
.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.action--btn {
  background-color: transparent;
  border: none;
  outline: none;
}
.description{
    height:80%;
    margin: 0;
    overflow:auto ;
}
.points--container,
.duration--container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  height: 30px;
}
.points--title,.Duration--title{
font-weight: 600;
}
.header--title{
  width: 80%;
  padding: 0;
  overflow: hidden;
  position: relative;
  display: inline-block;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000;
}
</style>
