<template>
  <div class="session_container" style="height:g100dvh">
    <div class="filter--container">
      <div class="filter-input-container">
        <label>{{$t("Name")}}</label>
        <el-input v-model="name"  class="filter-input" @input="getSessions()" placeholder="Please input" />
      </div>
      <div class="filter-input-container">
        <label>{{$t('Group')}}</label>
        <el-input v-model="group"  class="filter-input" @input="getSessions()" placeholder="Please input" />
      </div>
      <div class="filter-input-container">
        <label>{{$t("Module Name")}}</label>
        <el-input v-model="module_name"  class="filter-input" @input="getSessions()" placeholder="Please input" />

      </div>
      <div class="filter-input-container">
        <label>{{$t("Date")}}</label>
        <el-date-picker
        v-model="date"
        class="w-100 filter-input"
        style="border: none"
        @change="getSessions()"
        start="08:30"
        type="daterange"
        range-separator="To"
        step="00:15"
        end="18:30"
        placeholder="Select time"
      />      </div>
      <div class="filter-input-container">
        <label>{{$t("Time")}}</label>
        <el-time-select
        style="border: none"
        class="w-100 filter-input"
        v-model="time"
        @change="getSessions()"
        start="08:30"
        step="00:15"
        end="18:30"
        placeholder="Select time"
      />      </div>
      
      <div class="filter-input-container">
        <label>{{$t("Attendance")}}</label>
      <el-input v-model="attendance"  class="filter-input" @input="getSessions()" placeholder="Please input" />

      </div>

    </div>
    <el-table :data="sessions" class="desktop--table org--table sessions--table" style="width: 100%;
  max-height: 60vh;
">
      <el-table-column prop="group" label="Group" width="180" />

      <el-table-column prop="trainingName" label="Training" />
      <el-table-column prop="session_name" label="Session" />
      <el-table-column  label="Module Name" prop="name">
      </el-table-column>


      <el-table-column  label="Date" prop="date">
     
      </el-table-column>
      <el-table-column  label="Time" prop="time">
      
      </el-table-column>
      <el-table-column  label="Attendence" >
        <template #default="scope">
          {{scope.row.attendance}}%
        </template>  
      </el-table-column>
      <el-table-column fixed="right" v-if="canEditAttandance" label="" width="120">
        <template #default="scope" >
          <el-button
            link
            type="primary"
            class="edit--btn"
            @click="openSessionAttendenceM(scope.row)"
            size="small"
            >Edit</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div style="min-height: 100px" class="small--screen--table ">
            <div v-for="(item, index) in sessions" :key="index" :name="index"
                class="bg-white text-dark d-flex p-3 mobile---card">
                <div class="d-flex align-items-center justify-content-between flex-wrap w-90 px-5 align-self-end"
                    style="gap: 10px 32px">
                    <div class="w-100 d-flex gap-5">
                        <div class="d-flex flex-column w-50 align-items-start w-50 gap-1">
                            <span for="" class="info--keys">{{ $t("Group") }}</span>
                            <span for="" class="info--values">{{ item.group }}</span>
                        </div>
                        <div class="d-flex flex-column w-50 align-items-start  gap-1">
                          <span for="" class="info--keys">{{ $t("Training") }}</span>
                          <span for="" class="info--values">{{ item.trainingName }}</span>
                      </div>
                        <div class="d-flex flex-column w-50 align-items-start  gap-1">
                            <span for="" class="info--keys">{{ $t("Session") }}</span>
                            <span for="" class="info--values">{{ item.session_name }}</span>
                        </div>
                    </div>
                    <div class="w-100 d-flex gap-5">
                    
                    
                  <div class="d-flex flex-column w-50 align-items-start  gap-1">
                    <span for="" class="info--keys">{{ $t("Module Name") }}</span>
                    <span for="" class="info--values">{{ item.name }}</span>
                </div>

                <div class="d-flex flex-column w-50 align-items-start  gap-1">
                  <span for="" class="info--keys">{{ $t("Date") }}</span>
                  <span for="" class="info--values">{{ item.date }}</span>
              </div>
              <div class="d-flex flex-column w-50 align-items-start  gap-1">
                <span for="" class="info--keys">{{ $t("Time") }}</span>
                <span for="" class="info--values">{{ item.time }}</span>
            </div>
                    </div>

                    <div class="w-100 d-flex gap-5">
                    
                        <div class="d-flex flex-column w-50 align-items-start w-50  gap-1">
                            <span for="" class="info--keys">{{ $t("Attendence") }}</span>
                            <span for="" class="info--values">{{ item.attendance }}</span>
                        </div>
                        <div class="d-flex align-items-start justify-content-start flex-column  gap-1"v-if="canEditAttandance"
                            style="width: 33.33%">
                            <span for="" class="info--keys">{{ $t("Actions") }}</span>
                            <span for=""><el-button size="small" link type="primary" class="action_btn"
                                    @click="openSessionAttendenceM(item)"><i
                                        class="fa fa-pen"></i></el-button>
                                </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <div class="pagination-org">
      <div>
        <p style="font-size: 13px">
          {{ `${endIndex} / ${totalItems} ${$t("lines")}` }}
        </p>
      </div>

      <div>
        <el-pagination v-model:current-page="currentPage" v-model:page-size="perPage" :small="small"
        :background="background" layout="prev,pager, next" :total="total"  @size-change="handleSizeChange"
        @current-change="handleCurrentChange" />
      </div>
      <div class="d-flex">
        <span class="mt-2 mr-2" style="font-size: 13px"
          >{{ $t("Per page") }}:</span
        >

        <CSelect
          style="border: none"
          :value="perPage === this.sessions.length ? 'All' : perPage"
          :options="perOptions"
          @input="changePerPage($event.target.value)"
        >
        </CSelect>
      </div>
    </div>
  </div>
  <SessionAttendenceModal
    :selectedSession="selectedSession"
    :sessionAttendenceM="sessionAttendenceM"
    @closeSessionAttendenceM="closeSessionAttendenceM"
  />
</template>
<script>
import { ElLoading } from "element-plus";
import SessionAttendenceModal from "./SessionAttendenceModal.vue";
import { TrainingService } from "../../../services/training";

export default {
  name: "SessionTable",
  props:['canEditAttandance'],
  components: { SessionAttendenceModal },
  data() {
    return {
      sessionAttendenceM: false,
      currentPage: 1,
      perPage: 25,
      perOptions: [25, 50, 100, 200, "All"],

      multipleSelection: [],
      sessions: [
      ],
      selectedSession: {
        id: 0,
        name: "Session 1",
        participants: [
          { id: 0, firstname: "Hicham", lastname: "Alaoui", attended: false },
          { id: 1, firstname: "Hicham", lastname: "Alaoui", attended: true },
          { id: 2, firstname: "Hicham", lastname: "Alaoui", attended: false },
          { id: 3, firstname: "Hicham", lastname: "Alaoui", attended: true },
          { id: 4, firstname: "Hicham", lastname: "Alaoui", attended: true },
          { id: 5, firstname: "Hicham", lastname: "Alaoui", attended: false },
        ],
      },
      name: "",
    group: "",
    module_name: "",
    attendance: "",
    time: "",
    date: "",
    total:0,
    };
  },
  computed: {
    endIndex() {
      let end = this.currentPage * this.perPage;
      return end > this.totalItems ? this.totalItems : end;
    },
    totalItems() {
      return this.sessions ? this.sessions.length : 0;
    },
    paginatedItems() {
      if (this.sessions) {
        const start = (this.currentPage - 1) * this.perPage;
        const end = this.currentPage * this.perPage;

        const slicedArray = this.sessions.slice(start, end);

        return slicedArray;
      }
    },
  },
  mounted() {
    this.getSessions()
  },
  methods: {
    async getSessions(){
      const loading = ElLoading.service({
        fullscreen: true,
        background: "#0a1f8f45",
      });
      this.sessions=[]
      let payload =   {
    "name": this.name,
    "group": this.group,
    "module_name": this.module_name,
    "attendance": this.attendance,
    "time": this.time,
    "date": this.date
  }
      const {res,data}= await TrainingService.get_sessions(this.currentPage, this.perPage,payload );
if(res.status==200){
  this.sessions=[]
let modules = data.sessions.map(s=>s.modules.map(el=>({...el,training_id:s.training_id,trainingName:s.training_name,session_name:s.name,attendance:s.attendance,group:s.group,users:s.users})))
  this.sessions=data.sessions
  this.sessions=modules.flat()
  this.total=this.sessions.length
}else{
    showMessage('error',data)
}
loading.close();
    },
    openSessionAttendenceM(session) {
        this.selectedSession=session
      this.sessionAttendenceM = true;
    },
    closeSessionAttendenceM(status) {
      this.sessionAttendenceM = false;
      if(status) this.getSessions();
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getSessions();
    },
    handleSizeChange(val) {
      this.perPage = val
      this.getSessions();

    },
    changePerPage(value) {
      if (value === "All") {
        this.perPage = this.total;
        this.currentPage = 1;
      } else {
        this.perPage = value;
        this.currentPage = 1;
      }
      this.getSessions();

      this.displayedItems = Math.min(this.sessions.length, this.perPage);
      this.totalPages = Math.ceil(this.sessions.length / this.perPage);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handlePageChange(newPage) {
      this.currentPage = newPage;
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage;
      }
    },
    updateDisplayedItems() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = Math.min(start + this.perPage, this.sessions.length);
      this.displayedItems = end - start;
    },
    currentPage() {
      this.updateDisplayedItems();
    },
    perPage() {
      this.updateDisplayedItems();
    },
  },
};
</script>
<style>
.cell .edit--btn {
  display: none;
}
.el-table__row:hover .cell .edit--btn {
  font-weight: 900;
  color: #0071ce;
  display: block !important;
}
.sessions--table .el-table__body-wrapper {
    max-height: calc(100dvh - 450px) !important;
    overflow-y: auto;
}
</style>
<style scoped>
.training_container {
  display: flex;
  flex-direction: column;
  overflow: auto
}

.filter--container {
  display: flex;
  gap: 5px;
  margin-bottom: 5px;
  /* height: 60px; */
  flex-wrap: wrap
}

.filter-input-container {
  width: calc((100% - 25px) / 6);
  /* height: 40px; */
  border: none;
  box-shadow: none;
  outline: none;
  border-bottom: 1px solid #c3c3c3;
  display: flex;
  flex-direction: column;
}
.mobile---card {
    border: 1px solid #c3c3c3;
    border-bottom: none;
}

.small--screen--table {
    display: none;
}

@media (max-width: 970px) {
    .desktop--table {
        display: none;
    }

    .small--screen--table {
        display: block;
    }

    .org--table .el-table__body-wrapper {
        max-height: calc(100dvh - 380px);
    }
    .filter-input-container {
      flex: 0 1 calc(100% / 2 - 5px);
  }
}

@media (max-width: 770px) {
    .desktop--table {
        display: none;
    }

    .small--screen--table {
        display: block;
    }

    .org--table .el-table__body-wrapper {
        max-height: calc(100dvh - 380px);
    }
}
</style>
