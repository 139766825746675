<template>
  <el-dialog
    v-model="dialogVisible"
    :show-close="false"
    width="500"
    :before-close="() => $emit('closeAddModuleModal', false)"
    class="p-0"
  >
    <template #header="{ close, titleId, titleClass }">
      <div
        style="padding: 25px 32px 0 !important"
        class="d-flex justify-content-between"
      >
        <h3 :id="titleId">{{ isEdit ? "Edit Module" : $t("Add Module") }}</h3>
      </div>
    </template>
    <div class="container" style="padding: 0 32px !important">
      <div class="section--container">
        <div class="field--container">
          <label for="">Name*</label>
          <!-- <input type="text" v-model="name" /> -->
          <el-input v-model="name" :placeholder="$t('Module name')" />
        </div>
      </div>
      <div class="section--container">
        <div class="field--container">
          <label for="">Points*</label>
          <el-input-number
            v-model="points"
            :min="1"
            controls-position="right"
            style="width: 100%"
          >
          </el-input-number>
        </div>
      </div>
      <div class="field--container">
        <label for="">Description</label>
        <!-- <textarea
          name=""
          id=""
          cols="10"
          rows="3"
          v-model="description"
        ></textarea> -->
        <el-input
          v-model="description"
          :rows="4"
          type="textarea"
          :placeholder="$t('Module description')"
        />
      </div>
      <div class="field--container">
        <label for="">Duration</label>
        <el-select
          v-model="duration"
          placeholder="Select"
          @change="handleTime(session, scope.row)"
        >
          <el-option
            v-for="item in durationOptions"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
      <div class="section--container flex-column">
        <el-upload
          v-if="(!file.length && !isEdit) || isEdit"
          :accept="allowedExtensions.map((el) => '.' + el).join(', ')"
          class="w-100 mb-2"
          drag
          :show-file-list="false"
          multiple
          :auto-upload="false"
          :on-change="
            (uploadFile, uploadFiles) => handleUpload(uploadFile, uploadFiles)
          "
        >
          <el-icon class="el-icon--upload">
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="48" height="48" rx="24" fill="#E7F0FA" />
              <path
                d="M20.5 22.8535C20.3144 22.8535 20.1363 22.9273 20.0051 23.0585C19.8738 23.1898 19.8 23.3679 19.8 23.5535C19.8 23.7392 19.8738 23.9172 20.0051 24.0485C20.1363 24.1798 20.3144 24.2535 20.5 24.2535H27.5C27.6857 24.2535 27.8637 24.1798 27.995 24.0485C28.1263 23.9172 28.2 23.7392 28.2 23.5535C28.2 23.3679 28.1263 23.1898 27.995 23.0585C27.8637 22.9273 27.6857 22.8535 27.5 22.8535H20.5ZM19.8 26.3535C19.8 26.1679 19.8738 25.9898 20.0051 25.8585C20.1363 25.7273 20.3144 25.6535 20.5 25.6535H27.5C27.6857 25.6535 27.8637 25.7273 27.995 25.8585C28.1263 25.9898 28.2 26.1679 28.2 26.3535C28.2 26.5392 28.1263 26.7172 27.995 26.8485C27.8637 26.9798 27.6857 27.0535 27.5 27.0535H20.5C20.3144 27.0535 20.1363 26.9798 20.0051 26.8485C19.8738 26.7172 19.8 26.5392 19.8 26.3535ZM19.8 29.1535C19.8 28.9679 19.8738 28.7898 20.0051 28.6585C20.1363 28.5273 20.3144 28.4535 20.5 28.4535H23.3C23.4857 28.4535 23.6637 28.5273 23.795 28.6585C23.9263 28.7898 24 28.9679 24 29.1535C24 29.3392 23.9263 29.5172 23.795 29.6485C23.6637 29.7798 23.4857 29.8535 23.3 29.8535H20.5C20.3144 29.8535 20.1363 29.7798 20.0051 29.6485C19.8738 29.5172 19.8 29.3392 19.8 29.1535Z"
                fill="#0071CE"
              />
              <path
                d="M26.1001 13.0537H18.4001C17.6575 13.0537 16.9453 13.3487 16.4202 13.8738C15.8951 14.3989 15.6001 15.1111 15.6001 15.8537V32.6537C15.6001 33.3963 15.8951 34.1085 16.4202 34.6336C16.9453 35.1587 17.6575 35.4537 18.4001 35.4537H29.6001C30.3427 35.4537 31.0549 35.1587 31.58 34.6336C32.1051 34.1085 32.4001 33.3963 32.4001 32.6537V19.3537L26.1001 13.0537ZM26.1001 14.4537V17.2537C26.1001 17.8107 26.3213 18.3448 26.7152 18.7386C27.109 19.1325 27.6431 19.3537 28.2001 19.3537H31.0001V32.6537C31.0001 33.025 30.8526 33.3811 30.59 33.6437C30.3275 33.9062 29.9714 34.0537 29.6001 34.0537H18.4001C18.0288 34.0537 17.6727 33.9062 17.4101 33.6437C17.1476 33.3811 17.0001 33.025 17.0001 32.6537V15.8537C17.0001 15.4824 17.1476 15.1263 17.4101 14.8638C17.6727 14.6012 18.0288 14.4537 18.4001 14.4537H26.1001Z"
                fill="#0071CE"
              />
            </svg>
          </el-icon>

          <div class="el-upload__text d-flex flex-column gap-1">
            <p style="color: #0071ce; font-weight: 600; padding: 0; margin: 0">
              Click or drag & drop to upload a File
            </p>
            <small>File formats csv / xlsx / txt / zip / 7z / tar / gz</small>
          </div>
          <!-- <template #tip>
            <div class="el-upload__tip">
              File formats csv / xlsx / txt / zip / 7z / tar / gz
            </div>
          </template> -->
        </el-upload>
        <div
          class="d-flex flex-column w-100 gap-0"
          v-if="(file.length && !isEdit) || (file.length && isEdit)"
        >
          <div class="file" v-for="(item, index) in file" :key="index">
            <span
              ><svg-icon type="mdi" :path="fileIcon"></svg-icon>
              {{ item.name }}</span
            >
            <div class="file-actions">
              <el-button type="danger" @click="removeFromFile(item.id)" circle>
                <svg-icon type="mdi" :path="path"></svg-icon>
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <div
        class="dialog-footer d-flex gap-3 align-items-center justify-content-end"
        style="padding: 20px 32px"
      >
        <el-button
          @click="$emit('closeAddModuleModal', false)"
          class="border-0 text-primary"
          >Cancel</el-button
        >

        <CButton
          class="ra-button"
          v-if="!isEdit"
          :disabled="isNotEditD"
          @click="addModule"
          size="sm"
          color="primary"
          >Add Module</CButton
        >
        <CButton
          class="ra-button"
          v-else
          @click="editModule"
          size="sm"
          :disabled="isEditD"
          color="primary"
          >Edit Module</CButton
        >
      </div>
    </template>
  </el-dialog>
</template>
<script>
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiDelete, mdiFileCheck } from "@mdi/js";
import { FilesService } from "@/services/azureFiles";
import { ModuleService } from "@/services/module";
import { FileDragdrop } from "@mz/coreui-vue";
import { showMessage } from "../../../utils/helpers";
import { ElLoading } from "element-plus";
export default {
  name: "AddModuleModal",
  components: {
    SvgIcon,
    FileDragdrop,
  },
  props: { addModuleM: Boolean, isEdit: Boolean, selectedModule: Object },
  emits: ["closeAddModuleModal"],
  data() {
    return {
      file: new Array(),
      name: null,
      type: "hybrid",
      description: null,
      points: null,
      path: mdiDelete,
      duration: null,
      fileIcon: mdiFileCheck,
      dialogVisible: false,
      allowedExtensions: ["jpeg", "jpg", "png", "gif"],
      editedUploadedFiles: [],
      uploadedFiles: [],
      durationOptions: [
        {
          value: "0.5",
          label: "30 minutes",
        },
        {
          value: "1",
          label: "60 minutes",
        },
        {
          value: "1.5",
          label: "90 minutes",
        },
        {
          value: "2",
          label: "120 minutes",
        },
        {
          value: "2.5",
          label: "150 minutes",
        },
        {
          value: "3",
          label: "180 minutes",
        },
      ],
    };
  },
  computed: {
    isNotEditD() {
      return (
        this.name == null ||
        !this.name.trim().length ||
        this.type == null ||
        !this.type.trim().length ||
        this.description == null ||
        !this.description.trim().length ||
        this.points == null ||
        this.points == 0 ||
        this.file == null ||
        !this.file.length
      );
    },
    isEditD() {
      return (
        this.isNotEditD ||
        JSON.stringify({
          name: this.name,
          type: this.type,
          description: this.description,
          points: this.points,
          file: this.file.map((el) => el.id),
        }) ==
          JSON.stringify({
            name: this.selectedModule.name,
            type: this.selectedModule.type,
            description: this.selectedModule.description,
            points: this.selectedModule.points,
            file: this.selectedModule.files.map((el) => el.id),
          })
      );
    },
  },

  methods: {
    async handleUpload(uploadFile, uploadFiles) {
      this.file.push({ ...uploadFile, id: this.file.length + 1 });
      const loading = ElLoading.service({
        fullscreen: true,
        background: "#0a1f8f45",
      });
      const data = await FilesService.getUploadUrl("modules");
      this.uploadUrl = data.upload_url;
      this.fileId = data.file_id;
      this.uploadedFiles.push({
        name: uploadFile.name,
        id: data.file_id,
        fileUrl: data.upload_url,
        file: uploadFile,
      });
      if (this.isEditMode) {
        this.editedUploadedFiles.push(file.name);
      }
      loading.close();
    },
    resetFields() {
      this.name = null;
      (this.type = "hybrid"), (this.description = null);
      this.points = null;
      this.file = new Array();
      this.duration = null;
    },
    async addModule() {
      const dataTransfer = new DataTransfer();
      this.file = this.file.map((element) => element.raw);
      let uploadFileStatus = false;
      for (let index = 0; index < this.uploadedFiles.length; index++) {
        const element = this.uploadedFiles[index];

        console.log(element.file.raw, element.fileUrl);
        const uploadFileData = await FilesService.UploadFile(
          element.file.raw,
          element.fileUrl
        );
        uploadFileStatus = uploadFileData.success;
      }
      console.log(uploadFileStatus);
      if (uploadFileStatus) {
        const payload = {
          name: this.name,
          type: "hybrid",
          description: this.description,
          points: this.points,
          files: this.uploadedFiles.map((el) => ({ id: el.id, name: el.name })),
          duration: this.duration,
        };
        if (this.uploadedFiles.length > 0) {
          payload.files = this.uploadedFiles.map((el) => ({
            id: el.id,
            name: el.name,
          }));
        }

        const response = await ModuleService.createModule(payload);
        if (response.res.status == 200) {
          showMessage("success", response.data);
          this.$emit("closeAddModuleModal", true);
        } else {
          showMessage("error", response.data);
        }
      } else {
        showMessage("error", "Problem uploading file");
      }
    },
    async editModule() {
      var uploadStatus = true;
      var files = [];
      if (this.file.some((el) => Object.keys(el).includes("raw"))) {
        const { data } = await FilesService.uploadFiles(
          this.file
            .filter((el) => {
              if (Object.keys(el).includes("raw")) {
                return el.raw;
              }
            })
            .map((el) => el.raw)
        );
        files = data;
      }
      this.file
        .filter((el) => !Object.keys(el).includes("raw"))
        .map((el) => files.push(el));

      if (uploadStatus) {
        const payload = {
          name: this.name,
          type: "hybrid",
          description: this.description,
          points: this.points,
          files: files,
          step: 1,
        };
        const response = await ModuleService.updateModule(
          this.selectedModule.id,
          payload
        );
        if (response.res.status == 200) {
          showMessage("success", response.data);
          payload.step = 2;
          const secondResponse = await ModuleService.updateModule(
            this.selectedModule.id,
            payload
          );
          if (secondResponse.res.status == 200) {
            showMessage("success", secondResponse.data);
            this.$emit("closeAddModuleModal", true);
          } else {
            showMessage("error", secondResponse.data);
          }
        } else {
          showMessage("error", response.data);
        }
      }
    },
    removeFromFile(id) {
      this.file = this.file.filter((el) => el.id != id);
    },
  },
  watch: {
    addModuleM(val) {
      this.dialogVisible = val;
      if (val && this.isEdit) {
        this.name = this.selectedModule.name;
        this.type = this.selectedModule.type;
        this.description = this.selectedModule.description;
        this.duration = this.selectedModule.duration;
        this.points = this.selectedModule.points;
        console.log("selectedModule", this.selectedModule);
        this.file = this.selectedModule.files.map((el) => ({
          ...el,
          name: el.filename,
        }));
      }
      if (!val) {
        this.resetFields();
      }
    },
  },
};
</script>

<style>
.el-upload-dragger {
  border: 2px dashed #0071ce;
  border-radius: 0;
}
</style>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0;
}
.ra-button {
  font-weight: 500;
}
.field--container {
  display: flex;
  flex-direction: column;
  flex: 0 1 100%;
}

.section--container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
}

.half-width {
  flex: 0 1 50%;
}
.file {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  width: 100%;
}
.file-actions {
  display: flex;
}
.modal-body {
  padding: 0 1rem !important;
}
::v-deep .modal-header {
  padding: 1rem 1rem 0 1rem !important;
}
.modal-confirm {
  border-radius: 0px 0px 0px 25px;
  padding: 10px;
  width: 105px;
  margin-left: 10px;
}

::v-deep .el-select__wrapper {
  border-radius: 0;
}

::v-deep .el-input__wrapper {
  border-radius: 0;
}
::v-deep .el-textarea__inner {
  border-radius: 0;
}
::v-deep .modal-footer {
  padding: 0.75rem 39px !important;
}
::v-deep .modal-dialog {
  top: unset !important;
  left: unset !important;
}
::v-deep .el-input-number.is-controls-right .el-input__wrapper {
  border-color: rgb(223, 216, 216) !important;
}
</style>
