<template>
  <div class="main">
    <h2>{{ $t("Training courses") }}</h2>
    <div class="head--panel">
      <div class="sections--panel">
        <button
          v-for="(item, index) in sections"
          :key="index"
          @click="setSection(item.id)"
          :class="['section--btn', selectedSection == item.id && 'active']"
        >
          {{ $t(item.label) }}
        </button>
      </div>
      <el-dropdown
        ref="moduleAdd"
        trigger="contextmenu"
        type="primary"
        v-if="selectedSection != 0 && canManageModules"
        style="
          padding: 15px;
          display: flex;
          align-items: center;
          font-weight: normal;
          font-size: 14px;
          border-radius: 0px 0px 0px 25px;
          background: #0071ce;
          color: white;
        "
      >
        <span
          class="el-dropdown-link d-flex gap-1 align-items-center justify-content-center"
          @click="showClick('moduleAdd')"
        >
          {{ $t("Add Module") }}
          <i class="fa fa-chevron-down"></i>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="openAddModuleModal(false, {})">{{
              $t("Unique Module")
            }}</el-dropdown-item>

            <el-dropdown-item @click="openMultipleMember">{{
              $t("Multiple Modules")
            }}</el-dropdown-item>

            <el-dropdown-item @click="getModulesTemplate()">{{
              $t("Get Modules template")
            }}</el-dropdown-item>
            <!--  <el-dropdown-item divided>Action 5</el-dropdown-item> -->
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <CButton
        class="ra-button"
        size="sm"
        style="font-weight: normal; font-size: 14px; padding: 10px 25px"
        color="primary"
        @click="openAddTrainingModal"
        v-if="selectedSection == 0 && canManageTrainings"
        >{{ $t("Add Training") }}</CButton
      >
    </div>
    <div>
      <TrainingTable
        :refresh="refresh"
        :canManageTrainings="canManageTrainings"
        @toggleRefresh="toggleRefresh"
        :addTrainingModal="addTrainingModal"
        v-if="selectedSection == 0"
        @openAddTrainingModal="openAddTrainingModal"
      />
      <ModuleTable
        :refresh="refresh"
        :canEdit="canManageModules"
        :canManageModules="canManageTrainings"
        @openAddModuleModal="openAddModuleModal"
        @toggleRefresh="toggleRefresh"
        :addModuleM="addModuleM"
        v-if="selectedSection == 1"
      />
      <SessionTable
        v-if="selectedSection == 2"
        :canEditAttandance="canEditAttandance"
      />
    </div>
  </div>
  <AddTrainingModal
    :addTrainingModal="addTrainingModal"
    @closeAddTrainingModal="closeAddTrainingModal"
    :isEdit="isEdit"
    v-if="canManageTrainings"
    :selectedTraining="selectedTraining"
    :modules="modules"
  />
  <AddModuleModal
    :addModuleM="addModuleM"
    v-if="canManageTrainings"
    @closeAddModuleModal="closeAddModuleModal"
    :selectedModule="selectedModule"
    :isEdit="isEdit"
  />
  <div>
    <CModal
      :show="showMultipleModules"
      :draggable="false"
      class="m-auto modal-dialog-centered"
    >
      <div class="modal-body">
        <FileDragDrop
          title="Add Multiple Modules"
          label=""
          @upload="(uploadFiles) => handleUploadModules(uploadFiles, 1)"
        />
      </div>
      <template v-slot:header>
        <h3>{{ $t("Add Multiple Modules") }}</h3>
        <CButton @click="closeMultipleMember">
          <i class="fa fa-times"></i>
        </CButton>
      </template>
      <template v-slot:footer>
        <CButton @click="closeMultipleMember" color="link">{{
          $t("Close")
        }}</CButton>
      </template>
    </CModal>
  </div>
</template>
<script>
import TrainingTable from "./components/TrainingTable.vue";
import ModuleTable from "./components/ModuleTable.vue";
import AddTrainingModal from "./components/AddTrainingModal.vue";
import AddModuleModal from "./components/AddModuleModal.vue";
import SessionTable from "./components/SessionTable.vue";
import { useUserStore } from "@/stores/UserStore";
import { ModuleService } from "@/services/module";
import { toChildArray } from "@fullcalendar/core/preact";
import { ElLoading } from "element-plus";
import { showMessage } from "../../utils/helpers";

export default {
  name: "Training",
  components: {
    TrainingTable,
    AddTrainingModal,
    ModuleTable,
    AddModuleModal,
    SessionTable,
  },
  data() {
    return {
      userStore: useUserStore(),
      addTrainingModal: false,
      addModuleM: false,
      selectedSection: 0,
      selectedModule: null,
      showMultipleModules: false,
      refresh: false,
      selectedTraining: null,
      sections: [
        { id: 0, label: "Training courses" },
        { id: 1, label: "Modules" },
        { id: 2, label: "Sessions" },
      ],
      isEdit: false,
      permissions: [],
      modules: [],
    };
  },
  computed: {
    canViewAdminPage() {
      return (
        this.permissions.length &&
        this.permissions.some((el) => el.name == "viewAdminPage" && el.status)
      );
    },

    canManageTrainings() {
      return (
        this.permissions.length &&
        this.permissions.some((el) => el.name == "manageTrainings" && el.status)
      );
    },
    canManageModules() {
      return (
        this.permissions.length &&
        this.permissions.some((el) => el.name == "manageModules" && el.status)
      );
    },
    canEditAttandance() {
      return (
        this.permissions.length &&
        this.permissions.some((el) => el.name == "editAttandance" && el.status)
      );
    },
  },
  created() {
    this.permissions =
      this.userStore.currentPermissions.length &&
      this.userStore.currentPermissions[0].find((el) => el.name == "Training")
        .permissions;
    if (!this.canViewAdminPage) {
      this.$router.push({ path: "/home" });
    }
    this.getModules();
  },

  methods: {
    async getModules() {
      const loading = ElLoading.service({
        fullscreen: true,
        background: "#0a1f8f45",
      });
      // this.modules = [];
      const { res, data } = await ModuleService.getModules();
      if (res.status == 200) {
        this.modules = data.modules;
      } else {
        showMessage("error", data);
      }
      loading.close();
    },
    setSection(id) {
      this.selectedSection = id;
    },
    openAddTrainingModal(selectedTraining, isEdit) {
      this.addTrainingModal = true;
      this.selectedTraining = selectedTraining;
      this.isEdit = isEdit;
    },
    async getModulesTemplate() {
      await ModuleService.getTemplate();
    },

    openMultipleMember() {
      this.showMultipleModules = true;
    },
    closeMultipleMember() {
      this.showMultipleModules = false;
    },

    async handleUploadModules(file, fileId) {
      const loading = ElLoading.service({
        fullscreen: true,
        background: "#0a1f8f45",
      });

      const { data, status } = await ModuleService.bulkModulesAdd(file, fileId);
      if (status != true) {
        showMessage("error", data);
      } else {
        showMessage("success", data);
      }
      loading.close();
    },
    showClick(val) {
      if (val == "moduleAdd") {
        if (!this.$refs.moduleAdd) return;
        this.$refs.moduleAdd.handleOpen();
      } else {
        if (!this.$refs.export) return;
        this.$refs.export.handleOpen();
      }
    },
    closeAddTrainingModal(refresh) {
      this.isEdit = false;
      this.addTrainingModal = false;
      this.selectedTraining = null;
      this.refresh = refresh;
    },
    openAddModuleModal(isEdit, selectedModule) {
      this.addModuleM = true;
      this.selectedModule = selectedModule;
      this.isEdit = isEdit;
    },
    closeAddModuleModal(refresh) {
      this.isEdit = false;
      this.selectedModule = null;
      this.addModuleM = false;
      this.refresh = refresh;
    },
    toggleRefresh() {
      this.refresh = false;
    },
  },
};
</script>

<style scoped>
.main {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.head--panel {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sections--panel {
  display: flex;
  gap: 20px;
}
.section--btn {
  border: none;
  background: none;
  padding: 5px 0;
  outline: none;
}
.section--btn.active {
  color: #0071ce;
  position: relative;
}
.section--btn.active::before {
  content: "";
  width: 100%;
  height: 3px;
  background-color: #0071ce;
  position: absolute;
  bottom: 0;
}
</style>

<style>
.modal-header {
  border-bottom: none;
  display: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 1rem; */
  /* border-top-left-radius: calc(0.3rem - 1px); */
  /* border-top-right-radius: calc(0.3rem - 1px); */
  border-color: #d8dbe0;
}
.modal-footer {
  /* padding: 1rem; */
}
</style>
