<template>
  <el-dialog
    v-model="dialogVisible"
    title="Tips"
    :show-close="false"
    width="500"
    :before-close="handleClose"
  >
  <template #header="{ close, titleId, titleClass }">
      <div class="p-3 d-flex justify-content-between">
        <h3 :id="titleId">{{ session.name }}</h3>
      </div>
    </template>
    <el-table :data="session.users" empty-text="N/A" style="width: 100%">
    <el-table-column  label="Name" width="180" >
    <template #default="scope">
<span>{{ scope.row.firstname }} {{ scope.row.lastname }} </span>
    </template>  
    </el-table-column>
    <el-table-column  label="Attended" >
      <template #default="scope">
        <el-radio-group v-model="scope.row.attendance" class="ml-4">
      <el-radio :value="true" size="large"></el-radio>
    </el-radio-group>
    </template>   
    </el-table-column>
    <el-table-column  label="Not attended" >
      <template #default="scope">
        <el-radio-group v-model="scope.row.attendance" class="ml-4">
      <el-radio :value="false" size="large"></el-radio>
    </el-radio-group>
        </template>   
    </el-table-column>
  </el-table>
      <template #footer>
      <div
        class="dialog-footer d-flex gap-3 align-items-center justify-content-end"
      >
        <el-button
          @click="$emit('closeSessionAttendenceM',false)"
          class="border-0 text-primary"
          >Cancel</el-button
        >
        <CButton class="ra-button" @click="saveSessionAttendence" size="sm" color="primary"
          >Save</CButton
        >
      </div>
    </template>
  </el-dialog>
</template>
<script>
import { TrainingService } from '../../../services/training';
import { showMessage } from '../../../utils/helpers';

export default {
  props:['selectedSession','sessionAttendenceM'],
  emits:['closeSessionAttendenceM'],
  data() {
    return {
      dialogVisible: false,
      session:{},
    };
  },
  methods: {
    async saveSessionAttendence(){
      let session=this.session.users.map(el=>({attendance:el.attendance,user_id:el.user_id}))
      const {res,data} = await TrainingService.updateAttendance({training_id:this.session.training_id, list:session});
      if(res.status== 200 ){
        this.$emit('closeSessionAttendenceM',true)
        showMessage('success',data.message)
      }else{
        showMessage('error',data.message)
      }
    }
  },
  watch: {
    sessionAttendenceM(val) {
      this.dialogVisible = val;
      if (val) {
      this.session=JSON.parse(JSON.stringify(this.selectedSession));
      }else{
        this.session=Object.assign(this.session,{})
      }
    },
    selectedSession(val){
      this.session=JSON.parse(JSON.stringify(val));
    }
  },
};
</script>
<style></style>
