import { request } from "@/utils/request";
import { BASE_URL } from "@/constants/config";
import { ActiveUser } from "./user";

export const FILES_ENDPOINTS = {
  base: `${BASE_URL}api/modules/`,
  update: (id) => `${BASE_URL}api/modules/${id}/`,
  getOne: (id) => `${BASE_URL}api/modules/${id}/`,
  delete: (id) => `${BASE_URL}api/modules/${id}/`,
  getMany: `${BASE_URL}api/modules/`,
  getTemplate: `${BASE_URL}api/modules/bulk_template/`,
  bulkModulesAdd: `${BASE_URL}api/modules/bulk_upload/`,
};
class Module {
  async createModule({ name, type, description, points, files, duration }) {
    console.log({ name, type, description, points, files, duration });
    return request({
      url: FILES_ENDPOINTS.base,
      method: "POST",
      body: { name, type, description, points, files, duration },
    });
  }

  async updateModule(
    id,
    { name, type, description, points, files, duration, step }
  ) {
    return request({
      url: FILES_ENDPOINTS.update(id),
      method: "PUT",
      body: { name, type, description, points, files, duration, step },
    });
  }

  async getModule(id) {
    return request({
      url: FILES_ENDPOINTS.getOne(id),
      method: "GET",
    });
  }

  async deleteModule(ids) {
    return request({
      url: FILES_ENDPOINTS.base,
      method: "DELETE",
      body: { module_ids: ids },
    });
  }

  async getModules(page = 1, count = 10000) {
    return request({
      url: `${FILES_ENDPOINTS.getMany}?page=${page}&count=${count}`,
      method: "GET",
    });
  }
  async getTemplate() {
    var { data, res } = await request({
      url: `${FILES_ENDPOINTS.getTemplate}`,
      method: "POST",
      skipRedirect: true,
    });
    // Check if the response status is OK (200)
    if (res.ok) {
      const blob = await res.blob();

      // Create a temporary anchor element to trigger the download
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(blob);
      a.download = "modules template.xlsx"; // Specify the desired file name

      // Programmatically click the anchor element to trigger the download
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();

      // Remove the anchor element
      document.body.removeChild(a);
    } else {
      console.error("Failed to download the file");
    }
  }
  async bulkModulesAdd(file, fileId, onProgress = null) {
    // console.log(file)

    return new Promise((resolve, reject) => {
      const uploadedFilename = file.name;
      const formData = new FormData();
      // Files bigger then 80MB aren't supported with current nginx config
      if (file.size > 80000000) {
        reject("File is too big");
      }
      formData.append("file", file, uploadedFilename);
      formData.append("file_id", fileId);
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          onProgress && onProgress(100);
          if (xhr.status === 200) {
            resolve("File was uploaded successfully.");
          } else {
            redirectIfNotAuthenticated(xhr);
            try {
              const response = JSON.parse(xhr.responseText);
              reject(
                `Error occurred during upload. ${response?.detail?.message}.`
              );
            } catch {
              reject("Error occurred during upload.");
            }
          }
        }
      };

      xhr.onprogress = function (e) {
        if (e.lengthComputable) {
          onProgress && onProgress((e.loaded / e.total) * 100);
        }
      };
      onProgress && onProgress(0);

      xhr.open("post", FILES_ENDPOINTS.bulkModulesAdd);
      xhr.setRequestHeader("authorization", `Bearer ${ActiveUser.getToken()}`);
      xhr.send(formData);
    });
  }
}
export const ModuleService = new Module();
